import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { useDeleteQuery } from "../../../../../Services/API/APIHandlers";
import { formValuesTable } from "../../../../../Services/Types/MaterialType";
import { materialData } from "./../../../../../Services/Atoms/MaterialAtoms";

type propType = {
  data: formValuesTable;
};

function DeleteMaterial(props: propType) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [items, setItems] = useRecoilState(materialData);

  const {
    mutate: deleteMaterial,
    isLoading,
    error: materialDataError,
    data: materialDataResponse,
    isSuccess,
  } = useDeleteQuery("api/materials/" + props.data._id);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(
        items &&
          items.filter((item: formValuesTable) => item._id !== props.data._id)
      );
      toast.error(materialDataResponse?.data.message);
    }
  }, [isSuccess, materialDataResponse]);

  return (
    <>
      <IconButton
        icon={<FiTrash2 fontSize="1rem" />}
        className="rounded-circle"
        variant="ghost"
        colorScheme="red"
        aria-label="Delete member"
        onClick={onOpen}
      />
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"md"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ModalHeader>
              <h4 className="text-start">Are You Sure?</h4>
            </ModalHeader>

            <ModalBody
              className="border-1"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              pb={7}
            >
              <h5 className="text-start w-100">
                Following item will be permanently removed from Inventory
              </h5>
              <h5 style={{backgroundColor: "#ff000040"}} className="text-start p-2 text-center text-danger w-100 my-4">
                {props.data.item_name?.value}
              </h5>

              <span className="rounded-circle">
                <img
                  src="https://cdn.dribbble.com/users/592004/screenshots/2953817/___.gif"
                  className=""
                  alt=""
                  height={196}
                  width={196}
                />
              </span>
            </ModalBody>

            <ModalFooter display={"flex"} flexDirection={"row"}>
              <Button
                className="rounded-0"
                width={"100%"}
                onClick={(e: any) => {
                  e.preventDefault();
                  deleteMaterial();
                  onClose();
                }}
                marginRight={4}
                colorScheme={"red"}
              >
                {/* {isLoading ? <Spinner /> : "Delete"} */}
                Delete
              </Button>
              <Button className="rounded-0" width={"100%"} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteMaterial;
