import { useParams } from "react-router-dom";
import { InventoryTransaction } from "./TransactionData";

function InventoryTransactionState() {
    const { id } = useParams<{ id: string }>();
  return (
    <div>
      <InventoryTransaction id={id} />
    </div>
  );
}

export default InventoryTransactionState;
