import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import DeleteUser from "../Forms/UserModal/DeleteSite";
// import { members } from "./data";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { siteData } from "../../../../Services/Atoms/MaterialAtoms";
import { ProjectData } from "../../../../Services/Interfaces/UserInterface";
import UpdateSite from "./../Forms/UserModal/UpdateSite";

const generateRandomData = () => {
  const projects: Array<ProjectData> = [];
  const departments = ["HR", "Finance", "Development", "Marketing", "Sales"];
  const randomDate = () =>
    new Date(Date.now() + Math.floor(Math.random() * 1e10))
      .toISOString()
      .split("T")[0];

  for (let i = 0; i < 10; i++) {
    projects.push({
      project_name: `Project ${i + 1}`,
      project_id: `P-${Math.floor(Math.random() * 10000)}`,
      address: `${Math.floor(Math.random() * 1000)} Main St`,
      department: departments[Math.floor(Math.random() * departments.length)],
      start_date: randomDate(),
      end_date: randomDate(),
    });
  }
  return projects;
};

const projects = generateRandomData();

export const SitesTable = (props: TableProps) => {
  const [members, setMembers] = useRecoilState(siteData);
  const navigate = useNavigate();

  const {
    mutate: getUsers,
    isLoading,
    error: userDataError,
    data: usersData,
    isSuccess,
  } = useGet("api/fetch-all-sites");

  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      // Call a toast
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMembers(usersData.data);
    }
  }, [isSuccess, usersData]);

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className="mx-5 border-1"
      overflowY="auto"
      overflowX={"auto"}
      maxHeight="70vh"
    >
      <Table>
        <Thead position="sticky" top="0" zIndex="1" bg="gray.800">
          <Tr>
            <Th>
              <HStack spacing="3">
                <Checkbox />
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Name of Project</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Project ID</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <Checkbox />
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Address</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Name Of Department</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Start Date</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">End Date</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody fontSize={"15px"}>
          {members &&
            members.map((project, index) => (
              <Tr key={index}>
                <Td>
                  <HStack spacing="3">
                    <Checkbox />
                    <Box>
                      <Text className="mb-0" fontWeight="medium">
                        {project.project_name}
                      </Text>
                    </Box>
                  </HStack>
                </Td>
                <Td>
                  <Text className="mb-0" color="muted">
                    {project.project_id}
                  </Text>
                </Td>
                <Td>
                  <Text className="mb-0" color="#777">
                    {project.address}
                  </Text>
                </Td>
                <Td>
                  <Text className="mb-0" color="#777">
                    {project.department}
                  </Text>
                </Td>
                <Td>
                  <Text className="mb-0" color="muted">
                    {project.start_date}
                  </Text>
                </Td>
                <Td>
                  <Text className="mb-0" color="muted">
                    {project.end_date}
                  </Text>
                </Td>
                <Td>
                  <HStack spacing="1">
                    <DeleteUser projectData={project} />
                    <UpdateSite projectData={project} />
                    {/* <UpdateUser data={project} /> */}
                    <IconButton
                      variant={"ghost"}
                      className="rounded-circle"
                      aria-label="View Details"
                      onClick={() => navigate("/site-profile")}
                      icon={<BsArrowRight fontSize="1rem" />}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};
