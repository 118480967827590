import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGet } from "../../../Services/API/APIHandlers";
import { materialData } from "../../../Services/Atoms/MaterialAtoms";

function Header() {
  const [items, setItems] = useRecoilState(materialData);

  const [text, setText] = useState<string>("");

  const {
    mutate: getMaterialData,
    isLoading: isMaterialLoading,
    error: materialDataAllError,
    data: allMaterialsData,
    isSuccess: isAllMaterialSuccess,
  } = useGet("api/materials/all");

  const {
    mutate: search,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/search?q=" + text);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isAllMaterialSuccess) {
      console.log(allMaterialsData.data);
      setItems(allMaterialsData.data);
    }
  }, [isAllMaterialSuccess, allMaterialsData]);

  useEffect(() => {
    if (text.length === 0) {
      getMaterialData();
    }
  }, [text]);

  return (
    <>
      <Box px={{ base: "4", md: "6" }} py="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Link
              className="fs-7 py-1 d-flex flex-row align-items-center me-1"
              to="#"
              role="button"
              id="dropdownMenuLink"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <h4 className="my-0">Access Management</h4>
            </Link>
            <div className="d-flex align-items-center">
              <InputGroup maxW="xs">
                <InputRightElement pointerEvents="none"></InputRightElement>
                <Input
                  value={text}
                  onChange={(e: any) => setText(e.target.value)}
                  placeholder="Search"
                />
                <button
                  onClick={(e: any) => search()}
                  className="btn btn-primary text-light rounded py-0 px-2 me-2"
                >
                  <Icon
                    as={FaSearch}
                    // color="teal"
                    textColor={"#fff"}
                    boxSize="3"
                  />
                </button>
              </InputGroup>
            </div>
          </HStack>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
