import Header from "./Header";
import { SiteTransactionTable } from "./SiteTransactionTable";

function SiteTransaction() {
  return (
    <div>
        <Header />
      <SiteTransactionTable />
    </div>
  );
}

export default SiteTransaction;
