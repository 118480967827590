import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRepeat } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosList } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { transactionData } from "../../../../Services/Atoms/MaterialAtoms";
import ExportCSV from "../../../components/CSVExport/ExportToCSV";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

type propType = {
  csvData: Record<string, any>[] | undefined;
  csvFields: string[];
};

function Header(props: propType) {
  const [items, setItems] = useRecoilState(transactionData);

  const [text, setText] = useState<string>("");

  const {
    mutate: getMaterialData,
    isLoading: isMaterialLoading,
    error: materialDataAllError,
    data: allMaterialsData,
    isSuccess: isAllMaterialSuccess,
  } = useGet("api/materials/transactions");

  const {
    mutate: search,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/list?q=" + text);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isAllMaterialSuccess) {
      setItems(allMaterialsData.data);
    }
  }, [isAllMaterialSuccess, allMaterialsData]);

  useEffect(() => {
    if (text.length === 0) {
      getMaterialData();
    }
  }, [text]);

  return (
    <div className="navbar navbar-sticky border-bottom mb-4">
      <Box px={{ base: "4", md: "6" }} py="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <h4 className="my-0">Inventory Transactions</h4>
          </HStack>
          {/* <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup> */}
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
        </Stack>
      </Box>
      <Box px={{ base: "4", md: "6" }} py="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ExportCSV
              fields={props.csvFields}
              data={props.csvData}
              filename="material-transactions.csv"
            />
          </HStack>
          <div className="d-flex align-items-center">
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                search();
              }}
            >
              <InputGroup maxW="xs">
                <InputRightElement pointerEvents="none"></InputRightElement>
                <Input
                  value={text}
                  w={"100%"}
                  onChange={(e: any) => setText(e.target.value)}
                  className="rounded-0 border"
                  placeholder="Search"
                />
                <button className="btn btn-primary text-light rounded-0 py-0 px-3 me-2">
                  <Icon
                    as={FaSearch}
                    // color="teal"
                    textColor={"#fff"}
                    boxSize="4"
                  />
                </button>
              </InputGroup>
            </form>
            {/* <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span> */}
          </div>
        </Stack>
      </Box>
    </div>
  );
}

export default Header;
