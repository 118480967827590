// src/Services/API/APIHandlers.ts
import axios from "axios";
import { RecoilState, atom } from "recoil";
import {
  LabourPaymentValues,
  PartyPayment,
  ProjectData,
} from "../Interfaces/UserInterface";
import { formValuesTable } from "../Types/MaterialType";
import { TransactionType } from "../Types/TransactionType";

export const materialData: RecoilState<Array<formValuesTable> | null> =
  atom<Array<formValuesTable> | null>({
    key: "MATERIAL_DATA",
    default: null,
  });

interface Site {
  _id: string;
  siteId: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

export const transactionData: RecoilState<Array<TransactionType> | null> =
  atom<Array<TransactionType> | null>({
    key: "TransactionType",
    default: null,
  });

export const siteData: RecoilState<Array<ProjectData> | null> =
  atom<Array<ProjectData> | null>({
    key: "SITE_DATA",
    default: null,
  });

export const labourPaymentData: RecoilState<Array<LabourPaymentValues> | null> =
  atom<Array<LabourPaymentValues> | null>({
    key: "LabourPaymentValues",
    default: null,
  });

export const partyPaymentData = atom<PartyPayment[]>({
  key: "partyPaymentData",
  default: [],
});

export const siteObject: RecoilState<Site | null> = atom<Site | null>({
  key: "currentSite",
  default: {
    _id: "66be96fe3db19f197a7d031d",
    siteId: "BPC001",
    project_name: "WAREHOUSE",
    project_id: "BPC001",
    address: "Thakurgaon, Ranchi",
    department: "MATERIAL_MANAGEMENT",
    start_date: "2024-08-06T00:00:00.000Z",
  },
});

export const currentItemsAtom = atom<formValuesTable[] | null>({
  key: "currentItemsAtom", // Unique ID (with respect to other atoms/selectors)
  default: null, // Default value (initial value)
});

export const sidebarOpen = atom<boolean>({
  key: "sidebarOpen",
  default: true,
});

const api = axios.create({
  baseURL: "http://localhost:5000/api",
});

export const getPartyPayments = () =>
  api.get<PartyPayment[]>("/party-payments");
export const createPartyPayment = (payment: PartyPayment) =>
  api.post("/party-payments", payment);
export const updatePartyPayment = (id: string, payment: PartyPayment) =>
  api.put(`/party-payments/${id}`, payment);
export const deletePartyPayment = (id: string) =>
  api.delete(`/party-payments/${id}`);
