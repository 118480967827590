import {
  Box,
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet, usePost } from "../../../../Services/API/APIHandlers";
import {
  materialData,
  siteObject,
} from "../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../Services/Types/MaterialType";
import EditItem from "../Forms/UserModal/UpdateItem";
import DeleteMaterial from "./../Forms/UserModal/DeleteMaterial";
import Header from "./Header";

export const ItemsTable = (props: TableProps) => {
  const [items, setItems] = useRecoilState(materialData);
  const [dataSite, setDataSite] = useState(true);
  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);
  const site_Id = localStorage.getItem("site_id");
  const siteId = localStorage.getItem("siteId");

  const {
    mutate: getMaterialData,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/all");

  const {
    mutate: getMaterialDataSite,
    isLoading: isLoadingDataSite,
    error: materialDataSiteError,
    data: materialsDataSite,
    isSuccess: isSuccessSite,
  } = usePost("api/materials/site/get-site-data?siteId=" + siteId);

  useEffect(() => {
    if (materialDataSiteError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataSiteError?.response?.data.message);
    }
  }, [materialDataSiteError]);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isSuccessSite) {
      setItems(materialsDataSite.data);
    }
  }, [isSuccessSite, materialsDataSite]);

  useEffect(() => {
    if (siteId === "site001") getMaterialData();
    else getMaterialDataSite({ siteId: siteId });
    window.scrollTo(0, 0);
  }, []);

  // Define the fields for CSV export
  const csvFields = [
    "Item Name",
    "Brand Name",
    "Length/Volume",
    "L/V Unit",
    "Quantity",
    "Qty Unit",
    "Position",
  ];

  // Format the data for CSV export
  const csvData = items?.map((item) => ({
    "Item Name": item.item_name?.value,
    "Brand Name": item.brand_name,
    "Length/Volume": item.lv,
    "L/V Unit": item.lv_unit?.label,
    Quantity: item.quantity,
    "Qty Unit": item.qt_unit?.label,
    Position:
      (item.block?.label ? item.block?.label : "") +
      item.column?.label +
      item.cell?.label,
  }));

  return (
    <>
      <Header csvData={csvData} csvFields={csvFields} />
      <Box
        className="mx-5 border-1"
        overflowY="auto"
        overflowX={"auto"}
        maxHeight="70vh"
      >
        <Table {...props}>
          <Thead position="sticky" top="0" zIndex={4} bg="gray.800">
            <Tr>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">S. No.</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Item Name</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Brand Name</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Length/Volume</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">L/V Unit</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Quantity</Text>
                  </HStack>
                </HStack>
              </Th>

              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Qty Unit</Text>
                  </HStack>
                </HStack>
              </Th>

              {selectedSite?.siteId === "SITE001" ? (
                <Th>
                  <HStack spacing="3">
                    <HStack spacing="1">
                      <Text className="mb-0 text-light">Position</Text>
                    </HStack>
                  </HStack>
                </Th>
              ) : (
                <></>
              )}

              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"15px"}>
            {items ? (
              items.map((item: formValuesTable, index: number) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Text className="mb-0 fw-bold" color="muted">
                        {index + 1}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.item_name?.value}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.brand_name}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="muted">
                        {item.lv}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.lv_unit?.label}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.quantity ? item?.quantity : ""}
                      </Text>
                    </Td>
                    <Td>
                      <Text className="mb-0" color="#777">
                        {item.qt_unit?.label}
                      </Text>
                    </Td>
                    {selectedSite?.siteId === "SITE001" ? (
                      <Td>
                        <Text className="mb-0" color="#777">
                          {(item.block?.label ? item.block?.label : "") +
                            item.column?.label +
                            item.cell?.label}
                        </Text>
                      </Td>
                    ) : (
                      <></>
                    )}

                    <Td>
                      <HStack spacing="1">
                        <EditItem data={item} />
                        <DeleteMaterial data={item} />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <>Loading Data</>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
