import { IconButton, useDisclosure } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import {
  useGet,
  useLatePost,
  usePost,
} from "../../../../../Services/API/APIHandlers";
import {
  materialData,
  siteObject,
} from "../../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../../Services/Types/MaterialType";
import SiteOrderSlip from "./../../../SiteTransaction/SiteOrderSlip";

interface SelectOption {
  value: string | number;
  label: string | number;
}

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0, // No border radius
    borderColor: state.isFocused ? "red" : "#ddd", // Red when focused, gray otherwise
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? "red" : "#ddd", // Keep border color on hover
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: "black", // Black text color
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "black", // Ensure selected value's text is black
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 1000, // Ensure dropdown menu is on top
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "black", // Placeholder text color
  }),
};

const blockOptions: SelectOption[] = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "AT", label: "AT" },
  { value: "ABT", label: "ABT" },
  { value: "BCT", label: "BCT" },
  { value: "CT1", label: "CT1" },
  { value: "CT2", label: "CT2" },
  { value: "DT1", label: "DT1" },
  { value: "DT2", label: "DT2" },
];

const columnOptions: SelectOption[] = Array.from({ length: 16 }, (v, k) => ({
  value: k + 1,
  label: k + 1,
}));

const cellOptions: SelectOption[] = [
  { value: "001", label: "001" },
  { value: "002", label: "002" },
  { value: "003", label: "003" },
  { value: "004", label: "004" },
  { value: "005", label: "005" },
];

const validationSchema = yup.object().shape({
  item_name: yup.object().required("Item Name is required").nullable(),
  lv: yup.number().required("LV is required").nullable(),
  lv_unit: yup.object().required("LV Unit is required").nullable(),
  quantity: yup.number().required("Quantity is required").nullable(),
  qt_unit: yup.object().required("Quantity Unit is required").nullable(),
  block: yup.object().nullable(),
  column: yup.object().nullable(),
  cell: yup.object().nullable(),
});

type formValues = {
  _id: string | null;
  item_name: { _id: string; label: string; value: string } | null;
  brand_name: string;
  lv: number | null;
  lv_unit: null | SelectOption;
  quantity: number | null;
  qt_unit: null | SelectOption;
  block?: null | SelectOption;
  column?: null | SelectOption;
  cell?: null | SelectOption;
};

const lvUnitOptions: SelectOption[] = [
  { value: "inch", label: "inch" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
  { value: "ft", label: "ft" },
  { value: "litre", label: "litre" },
  { value: "kg", label: "kg" },
];

const qtUnitOptions: SelectOption[] = [
  { value: "kg", label: "kg" },
  { value: "litre", label: "litre" },
  { value: "gm", label: "gm" },
  { value: "pc", label: "pc" },
  { value: "bags", label: "bags" },
];

type pointerSet = {
  text: string;
  size: number;
  xCord: number;
  yCord: number;
  style: string;
  font: string;
};

const generateSelectOptions = (options: any): SelectOption[] => {
  console.log(options);
  return options.map((option: any) => ({
    value: option.name,
    label: option.name,
  }));
};

const generateSelectOptionData = (option: any): SelectOption => {
  return {
    value: option.name,
    label: option.name,
  };
};

function generateObject(
  data: formValuesTable,
  index: number
): Array<pointerSet> {
  const { item_name, lv_unit, qt_unit, quantity } = data;

  const objectArray: Array<pointerSet> = [
    {
      text: (index + 1).toString(),
      size: 28,
      xCord: 125,
      yCord: 710 + index * 50,
      style: "600",
      font: "Arial",
    },
    {
      text: `${item_name?.value} ${lv_unit?.value}`,
      size: 28,
      xCord: 200,
      yCord: 710 + index * 50,
      style: "600",
      font: "Arial",
    },
    {
      text: qt_unit?.value?.toString() || "",
      size: 28,
      xCord: 1230,
      yCord: 710 + index * 50,
      style: "600",
      font: "Arial",
    },
    {
      text: quantity?.toString() || "",
      size: 28,
      xCord: 1200,
      yCord: 710 + index * 50,
      style: "600",
      font: "Arial",
    },
  ];

  return objectArray;
}

const NewRequirement = () => {
  const [challanFormData, setChallanFormData] = useState<any>();
  const [initialValues, setInitialValues] = useState<formValues>({
    _id: "",
    item_name: null,
    brand_name: "",
    lv: 0,
    lv_unit: null as SelectOption | null,
    quantity: 0,
    qt_unit: null as SelectOption | null,
    block: null as SelectOption | null,
    column: null as SelectOption | null,
    cell: null as SelectOption | null,
  });
  // {
  //   isOpen: boolean;
  //   onOpen: () => void;
  //   onClose: () => void;
  // > = ({ isOpen, onOpen, onClose }) => {
  const [mainTableItems, setMainTableItems] = useRecoilState(materialData);
  const [items, setItems] = useState<Array<formValuesTable> | null>(null);
  const [currentItems, setCurrentItems] =
    useState<Array<formValuesTable> | null>(null);

  const [qtUnitDataState, setQtUnitDataState] = useState<Array<SelectOption>>(
    []
  );
  const [lvUnitDataState, setLvUnitDataState] = useState<Array<SelectOption>>(
    []
  );

  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);

  const [barItems, setBarItems] = useState<
    Array<{ _id: string; label: string; value: string }>
  >([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState(false);
  const [itemList, setItemList] = useState<any>([]);

  const [updateCreate, setUpdateCreate] = useState<boolean>(false);

  const [currentEditingIndex, setCurrentEditingIndex] = useState<number>(0);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (items && currentEditingIndex < items?.length) {
        setItems(
          items &&
            items.map((item: any, index: number) =>
              currentEditingIndex === index ? values : item
            )
        );
      } else {
        setItems(items ? [...items, values as any] : [values]);
      }

      setItemList(
        items
          ? [...items, { itemRefId: values._id, quantity: values.quantity }]
          : [{ itemRefId: values._id, quantity: values.quantity }]
      );
      setUpdateCreate(false);
      onClose();
    },
  });

  const [itemsDisplay, setItemsDisplay] = useState<Array<
    Array<pointerSet>
  > | null>(null);

  useEffect(() => {
    if (items) {
      let generateItemData = items.map(
        (item: formValuesTable, index: number) => {
          return generateObject(item, index);
        }
      );

      setItemsDisplay(generateItemData);
    }
  }, [items]);

  // useEffect(() => {
  //   alert(itemList);
  // }, [itemList]);

  useEffect(() => {
    setCurrentEditingIndex(items ? items.length : 0);
  }, [items]);

  const {
    mutate: createMaterialItems,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = usePost(`api/transaction/site`);

  const {
    mutate: listBars,
    isLoading: isLoadingbars,
    error: listDataError,
    data: listBarData,
    isSuccess: isSuccessListBars,
  } = useLatePost();

  const {
    mutate: getQtUnit,
    isLoading: isLoadingQtUnit,
    error: listQtUnitError,
    data: qtUnitData,
    isSuccess: isSuccessQtUnit,
  } = useGet("api/qt-units/");

  console.log(challanFormData);

  const {
    mutate: createQtUnit,
    isLoading: isLoadingQtUnitCreate,
    error: listQtUnitCreateError,
    data: qtUnitCreateData,
    isSuccess: isSuccessQtUnitCreate,
  } = usePost("api/qt-units/");

  useEffect(() => {
    getQtUnit();
  }, []);

  useEffect(() => {
    if (qtUnitCreateData) {
      // Call a toast
      setQtUnitDataState([
        ...qtUnitDataState,
        generateSelectOptionData(qtUnitCreateData.data),
      ]);
    }
  }, [qtUnitCreateData]);

  useEffect(() => {
    if (qtUnitData) {
      // Call a toast
      setQtUnitDataState(generateSelectOptions(qtUnitData.data));
    }
  }, [qtUnitData]);

  useEffect(() => {
    if (listQtUnitError instanceof AxiosError) {
      // Call a toast
      toast.error(listQtUnitError?.response?.data.message);
    }
  }, [listQtUnitError]);
  const [challanURL, setChallanUrl] = useState<string>("");

  const {
    mutate: getLvUnit,
    isLoading: isLoadingLvUnit,
    error: listLvUnitError,
    data: lvUnitData,
    isSuccess: isSuccessLvUnit,
  } = useGet("api/lv-units/");

  const {
    mutate: createLvUnit,
    isLoading: isLoadingLvUnitCreate,
    error: listLvUnitCreateError,
    data: lvUnitCreateData,
    isSuccess: isSuccessLvUnitCreate,
  } = usePost("api/lv-units/");

  useEffect(() => {
    getLvUnit();
  }, []);

  useEffect(() => {
    if (lvUnitCreateData) {
      // Call a toast
      setLvUnitDataState([
        ...lvUnitDataState,
        generateSelectOptionData(lvUnitCreateData.data),
      ]);
    }
  }, [lvUnitCreateData]);

  useEffect(() => {
    if (lvUnitData) {
      // Call a toast
      setLvUnitDataState(generateSelectOptions(lvUnitData.data));
    }
  }, [lvUnitData]);


  useEffect(() => {
    if (listLvUnitError instanceof AxiosError) {
      // Call a toast
      toast.error(listLvUnitError?.response?.data.message);
    }
  }, [listLvUnitError]);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMainTableItems(items ? materialsData.data.item : null);

      formik.setValues({ ...initialValues, quantity: 0, lv: 0 });
      setItems(null);
      toast.success(materialsData.data.message);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (isSuccessListBars && listBarData) {
      setBarItems(
        listBarData.data.map((item: formValuesTable) => {
          return {
            _id: item._id,
            value: item.item_name?.value,
            label: item.item_name?.label,
          };
        })
      );
      setCurrentItems(listBarData.data);
    }
  }, [isSuccessListBars, listBarData]);

  console.log(formik.values);
  console.log(challanFormData);

  return (
    <>
      <Link
        onClick={() => setShow(true)}
        className="btn btn-outline-primary rounded-0 fs-7 px-4"
        to="#"
      >
        New Requirement
      </Link>

      <Modal
        isCentered
        onClose={onClose}
        fullscreen={true}
        // show={isOpen}
        onHide={() => setShow(false)}
        show={show}
        className="w-100"
        size="xl"
      >
        <Modal.Dialog style={{ maxWidth: "inherit" }}>
          <Modal.Body className="w-100" style={{ padding: "20px" }}>
            <Modal.Header closeButton>
              <Modal.Title className="fs-1">
                Create New Requirement Challan
              </Modal.Title>
            </Modal.Header>
            <Row>
              <Col md={5}>
                <Form onSubmit={formik.handleSubmit}>
                  <Card className="rounded-5 mt-3 border-0">
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <h4>Items</h4>
                          <Form.Group className="pt-3" controlId="item_name">
                            <Form.Label className="badge text-dark pb-0 mb-0">
                              Item Name
                            </Form.Label>
                            <CreatableSelect
                              isClearable
                              className="border-0"
                              styles={customStyles} // Apply custom styles
                              options={barItems}
                              value={formik.values.item_name}
                              onChange={(option: any) => {
                                formik.setFieldValue("item_name", option);

                                if (option) {
                                  const foundItem = currentItems?.find(
                                    (value) => value._id === option._id
                                  );

                                  if (foundItem) {
                                    formik.setValues({
                                      ...foundItem,
                                      quantity: 0,
                                    });
                                  } else {
                                    formik.setValues({
                                      ...formik.values,
                                      item_name: option,
                                      _id: "",
                                      quantity: 0,
                                    });
                                  }
                                }
                              }}
                              onInputChange={(inputValue, actionMeta) => {
                                if (
                                  actionMeta.action === "input-change" &&
                                  inputValue.match(/[a-zA-Z]/)
                                ) {
                                  listBars({
                                    route:
                                      "api/materials/site/search-data?q=" +
                                      inputValue,
                                    data: {
                                      siteId: selectedSite?.siteId,
                                    },
                                  });
                                }
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("item_name", true)
                              }
                            />
                            {formik.touched.item_name &&
                              formik.errors.item_name && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.item_name}
                                </div>
                              )}
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="pt-3" controlId="lv">
                            <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                              Brand Name
                            </Form.Label>
                            <Form.Control
                              className="rounded-0"
                              type="text"
                              placeholder="Your Brand"
                              {...formik.getFieldProps("brand_name")}
                              isInvalid={
                                formik.touched.brand_name &&
                                !!formik.errors.brand_name
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.brand_name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="lv">
                            <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                              Length/Volume
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Length or Volume"
                              className="rounded-0"
                              {...formik.getFieldProps("lv")}
                              isInvalid={
                                formik.touched.lv && !!formik.errors.lv
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.lv}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="lv_unit">
                            <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                              LV Unit
                            </Form.Label>
                            <CreatableSelect
                              isClearable
                              options={lvUnitDataState}
                              styles={customStyles} // Apply custom styles
                              value={formik.values.lv_unit}
                              onChange={(option) =>
                                formik.setFieldValue("lv_unit", option)
                              }
                              onCreateOption={(inputValue) => {
                                // Create a new LV Unit when an option is not found
                                createLvUnit({ name: inputValue });
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("lv_unit", true)
                              }
                            />
                            {formik.touched.lv_unit &&
                              formik.errors.lv_unit && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.lv_unit}
                                </div>
                              )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="quantity">
                            <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                              Quantity
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Quantity"
                              className="rounded-0"
                              {...formik.getFieldProps("quantity")}
                              isInvalid={
                                formik.touched.quantity &&
                                !!formik.errors.quantity
                              }
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (inputValue < 0) {
                                  formik.setFieldValue("quantity", 0);
                                } else {
                                  formik.setFieldValue("quantity", inputValue);
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.quantity}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="qt_unit">
                            <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                              Quantity Unit
                            </Form.Label>
                            <CreatableSelect
                              isClearable
                              options={qtUnitDataState}
                              styles={customStyles} // Apply custom styles
                              value={formik.values.qt_unit}
                              onChange={(option) =>
                                formik.setFieldValue("qt_unit", option)
                              }
                              onCreateOption={(inputValue) => {
                                // Create a new Quantity Unit when an option is not found
                                createQtUnit({ name: inputValue });
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("qt_unit", true)
                              }
                            />
                            {formik.touched.qt_unit &&
                              formik.errors.qt_unit && (
                                <div className="invalid-feedback d-block">
                                  {formik.errors.qt_unit}
                                </div>
                              )}
                          </Form.Group>
                        </Col>

                        {selectedSite?.siteId === "SITE001" ? (
                          <>
                            <Col md={6}>
                              <Form.Group className="pt-3" controlId="block">
                                <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                                  Block
                                </Form.Label>
                                <Select
                                  options={blockOptions}
                                  styles={customStyles} // Apply custom styles
                                  value={formik.values.block}
                                  onChange={(option) =>
                                    formik.setFieldValue("block", option)
                                  }
                                  onBlur={() =>
                                    formik.setFieldTouched("block", true)
                                  }
                                />
                                {formik.touched.block &&
                                  formik.errors.block && (
                                    <div className="invalid-feedback d-block">
                                      {formik.errors.block}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="pt-3" controlId="column">
                                <Form.Label className="badge text-dark pb-0 mb-0 rounded-0">
                                  Column
                                </Form.Label>
                                <Select
                                  options={columnOptions}
                                  styles={customStyles} // Apply custom styles
                                  value={formik.values.column}
                                  onChange={(option) =>
                                    formik.setFieldValue("column", option)
                                  }
                                  onBlur={() =>
                                    formik.setFieldTouched("column", true)
                                  }
                                />
                                {formik.touched.column &&
                                  formik.errors.column && (
                                    <div className="invalid-feedback d-block">
                                      {formik.errors.column}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="pt-3" controlId="cell">
                                <Form.Label className="badge text-dark pb-0 mb-0">
                                  Cell
                                </Form.Label>
                                <Select
                                  options={cellOptions}
                                  styles={customStyles} // Apply custom styles
                                  value={formik.values.cell}
                                  onChange={(option) =>
                                    formik.setFieldValue("cell", option)
                                  }
                                  onBlur={() =>
                                    formik.setFieldTouched("cell", true)
                                  }
                                />
                                {formik.touched.cell && formik.errors.cell && (
                                  <div className="invalid-feedback d-block">
                                    {formik.errors.cell}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                        <Col md={12}>
                          <Button
                            className="btn btn-primary mt-4 d-block text-uppercase fw-bold w-100"
                            type="submit"
                          >
                            {updateCreate ? "Update Current " : "Add / Create "}
                            &nbsp; Item
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md={7}>
                <h4 className="text-center mt-4">Items List</h4>
                <Table bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Item Name</th>
                      <th>LV</th>
                      <th>LV Unit</th>
                      <th>Quantity</th>
                      <th>Quantity Unit</th>
                      {selectedSite?.siteId === "SITE001" ? (
                        <>
                          <th>Block</th>
                          <th>Column</th>
                          <th>Cell</th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items &&
                      items.map((item: formValuesTable, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.item_name?.value}</td>
                          <td>{item.lv}</td>
                          <td>{item.lv_unit?.label}</td>
                          <td>{item.quantity ? item?.quantity : ""}</td>
                          <td>{item.qt_unit?.label}</td>
                          {selectedSite?.siteId === "SITE001" ? (
                            <>
                              <td>{item.block?.label}</td>
                              <td>{item.column?.label}</td>
                              <td>{item.cell?.label}</td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td>
                            <IconButton
                              className="rounded-circle"
                              icon={<FiEdit2 fontSize="1rem" />}
                              variant="ghost"
                              aria-label="Update User Info"
                              onClick={onOpen}
                            />
                            <IconButton
                              icon={<FiTrash2 fontSize="1rem" />}
                              className="rounded-circle"
                              variant="ghost"
                              aria-label="Delete member"
                              onClick={() => {
                                setInitialValues(item);
                                setCurrentEditingIndex(index);
                                formik.setValues(item);
                                setUpdateCreate(true);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {/* <DeliveryChallanForm
                  challanURL={challanURL}
                  setChallanUrl={setChallanUrl}
                  formData={itemsDisplay}
                  challanFormData={challanFormData}
                  setChallanFormData={setChallanFormData}
                  submit={false}
                /> */}
                <SiteOrderSlip
                  setChallanFormData={setChallanFormData}
                  challanFormData={challanFormData}
                  formData={itemsDisplay}
                  challanURL={challanURL}
                  setChallanUrl={setChallanUrl}
                />
                <Button
                  className="btn btn-danger mt-4 d-block text-uppercase fw-bold w-100"
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (items)
                      createMaterialItems({
                        itemsList: itemList,
                        status: "PENDING",
                        base_site: selectedSite?._id,
                        type: "INWARD",
                        deliveryChallanData: challanFormData,
                        challanLink: challanURL,
                      });
                  }}
                  disabled={items ? items.length === 0 : false}
                >
                  Request Challan
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default NewRequirement;
