import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  FiBookmark,
  FiChevronDown,
  FiChevronUp,
  FiHelpCircle,
  FiSettings,
  FiUsers,
} from "react-icons/fi";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accessType } from "../../../Services/Atoms/AuthAtoms";
import { sidebarOpen } from "../../../Services/Atoms/MaterialAtoms";
import { NavButton } from "./NavButton";

type SidebarProps = {
  isOpen: boolean;
  onToggle: () => void;
  children: ReactNode;
};

function Sidebar({ isOpen, onToggle, children }: SidebarProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarOpen);
  // const [sidebarWidth, setSidebarWidth] = useState<number>(256);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [activeNav, setActiveNav] = useState<string>("Users");
  const [currentSelected, setCurrentSelected] = useState("user");
  const [accessTypeData, setAccessTypeData] = useRecoilState(accessType);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isMaterialOpen, setIsMaterialOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Sidebar width when open and closed
  const sidebarWidth = isOpen ? "284px" : "70px";
  const sidebarWidthMobile = isOpen ? "284px" : "0px";

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(isOpen);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  const handleNavClick = (label: string, path: string) => {
    setActiveNav(label);
    navigate(path);
  };

  return (
    <>
      <Flex height="100vh" overflow="hidden">
        {/* Sidebar */}
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          position={isMobile ? "fixed" : "relative"} // Absolute positioning in mobile mode
          backgroundColor="gray.800"
          color="white"
          height="100vh"
          width={isMobile ? sidebarWidthMobile : sidebarWidth}
          transition="width 0.3s ease"
          p={4}
          zIndex={2} // Ensure sidebar is above preview pane when open
        >
          <Stack shouldWrapChildren width="100%" spacing="1">
            <HStack
              paddingY={4}
              paddingBottom={6}
              width="100%"
              position={"relative"}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <img
                src="https://res.cloudinary.com/dfhnhykia/image/upload/v1726766565/bpc/bezletbsmyonuzrfyjih.png"
                alt=""
                height={150}
                width={150}
              />
              {isSidebarOpen ? (
                <IconButton
                  variant="ghost"
                  position={"absolute"}
                  right={-4}
                  className=" border-start border-top border-bottom rounded-0 rounded-start"
                  aria-label="Toggle Sidebar"
                  colorScheme="white"
                  bg="white"
                  color="black"
                  icon={<LuArrowLeftToLine fontSize="1rem" />}
                  onClick={onToggle}
                />
              ) : (
                <></>
              )}
            </HStack>
            <Stack spacing="1" className="mt-4">
              <NavButton
                head={true}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavClick("Site", "/sites");
                  setCurrentSelected("Site");
                }}
                label="Site"
                icon={FiBookmark}
                variant={activeNav === "Site" ? "solid" : "ghost"}
              />
              <NavButton
                onClick={() => setIsAccountOpen(!isAccountOpen)}
                label="Material Management"
                icon={isAccountOpen ? FiChevronUp : FiChevronDown}
                head={true}
                variant={
                  activeNav === "Labour Payment" ||
                  activeNav === "Party Payment"
                    ? "solid"
                    : "ghost"
                }
              />
              <Collapse in={isAccountOpen}>
                <Stack pl={6} mt={1} spacing={1}>
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("Material Management", "/inventory");
                      setCurrentSelected("Inventory");
                    }}
                    label="Inventory"
                    icon={FiBookmark}
                    variant={
                      activeNav === "Material Management" ? "solid" : "ghost"
                    }
                  />
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("Site Transaction", "/site-transaction");
                      setCurrentSelected("Site Transaction");
                    }}
                    label="Site Transaction"
                    icon={FiBookmark}
                    variant={
                      activeNav === "Site Transaction" ? "solid" : "ghost"
                    }
                  />
                  <NavButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick(
                        "Material Transactions",
                        "/inventory/transactions"
                      );
                      setCurrentSelected("Material Transaction");
                    }}
                    label="Material Transaction"
                    icon={FiBookmark}
                    variant={
                      activeNav === "Material Transaction" ? "solid" : "ghost"
                    }
                  />
                </Stack>
              </Collapse>
              {accessTypeData === "SUPER_ADMIN" ||
              accessTypeData === "SUPER ADMIN" ? (
                <NavButton
                  head={true}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavClick("Users", "/dashboard");
                    setCurrentSelected("Users");
                  }}
                  label="Users"
                  icon={FiUsers}
                  variant={activeNav === "Users" ? "solid" : "ghost"}
                />
              ) : null}
            </Stack>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            <Stack spacing="1">
              <Divider />
              <NavButton
                label="Access Management"
                icon={FiHelpCircle}
                onClick={() => {
                  setCurrentSelected("Help");
                  handleNavClick("Help", "/access-management");
                }}
                variant={activeNav === "Help" ? "solid" : "ghost"}
              />
              <NavButton
                isDisabled={true}
                label="Help"
                icon={FiHelpCircle}
                onClick={() => {
                  setCurrentSelected("Help");
                  handleNavClick("Help", "/help");
                }}
                variant={activeNav === "Help" ? "solid" : "ghost"}
              />
              <NavButton
                label="Settings"
                icon={FiSettings}
                onClick={() => {
                  setCurrentSelected("Settings");
                  handleNavClick("Settings", "/settings");
                }}
                variant={activeNav === "Settings" ? "solid" : "ghost"}
              />
            </Stack>
          </Stack>
        </Flex>

        {/* Preview Pane */}
        <Box
          flex="1"
          bg="gray.100"
          ml={isMobile && isOpen ? sidebarWidthMobile : 0} // Adjust margin only for mobile mode when open
          transition="margin-left 0.3s ease"
          p={6}
          overflow="auto"
          zIndex={1} // Lower zIndex to allow sidebar overlap
        >
          {children}
        </Box>
      </Flex>
      {!isOpen && (
        <IconButton
          variant="ghost"
          bg="#fff"
          className="rounded-circle shadow"
          aria-label="Toggle Sidebar"
          icon={<LuArrowRightToLine fontSize="0.85rem" />}
          onClick={onToggle}
          position="fixed"
          zIndex={2}
          style={{ top: "117px", left: "64px" }}
          size="sm"
        />
      )}
    </>
  );
}

export default Sidebar;
