import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "../Auth/Login";
import { NotificationProvider } from "../Contexts/NotificationContext";
import AccessManagement from "../Dashboard/Pages/AccessManagement";
import DashboardHome from "../Dashboard/Pages/DashboardHome";
import HR from "../Dashboard/Pages/HR";
import Inventory from "../Dashboard/Pages/Inventory";
import InventoryTransactionState from "../Dashboard/Pages/InventoryTransaction";
import PartyPayment from "../Dashboard/Pages/PartyPayment";
import Settings from "../Dashboard/Pages/Settings";
import SiteSection from "../Dashboard/Pages/Site";
import SiteProfile from "../Dashboard/Pages/Site/SitesTable/SiteProfile";
import SiteTransaction from "../Dashboard/Pages/SiteTransaction";
import SiteOrderVerification from "../Dashboard/Pages/SiteTransaction/SiteOrderVerification";
import DepartmentLoader from "../Dashboard/SpecialComponents/DepartmentLoader";
import { isTokenValid } from "../Services/API/APIHandlers";
import AuthRoute from "../Services/Routes/AuthRoutes";
import LabourPayment from "./../Dashboard/Pages/LabourPayment/index";

const customTheme = extendTheme({
  components: {
    Table: {
      baseStyle: {
        bg: "white",
      },
      variants: {
        custom: {
          bg: "white",
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    isTokenValid();
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <div className="App">
        <Router>
          <NotificationProvider>
            <Toaster position="bottom-center" reverseOrder={false} />
            <Routes>
              <Route path="/" element={<Login />} />
              {/* <Route path="/" element={<Login />} /> */}
              <Route path="/department/:id" element={<DepartmentLoader />} />
              <Route
                path="/dashboard"
                element={
                  <AuthRoute>
                    <DashboardHome />
                  </AuthRoute>
                }
              />
              <Route
                path="/inventory"
                element={
                  <AuthRoute>
                    <Inventory />
                  </AuthRoute>
                }
              />
              <Route
                path="/sites"
                element={
                  <AuthRoute>
                    <SiteSection />
                  </AuthRoute>
                }
              />
              <Route
                path="/labour-payment"
                element={
                  <AuthRoute>
                    <LabourPayment />
                  </AuthRoute>
                }
              />
              <Route
                path="/party-payment"
                element={
                  <AuthRoute>
                    <PartyPayment />
                  </AuthRoute>
                }
              />
              <Route
                path="/access-management"
                element={
                  <AuthRoute>
                    <AccessManagement />
                  </AuthRoute>
                }
              />
              <Route
                path="/access-management/sites"
                element={
                  <AuthRoute>
                    <AccessManagement />
                  </AuthRoute>
                }
              />
              <Route
                path="/site-transaction"
                element={
                  <AuthRoute>
                    <SiteTransaction />
                  </AuthRoute>
                }
              />
              <Route
                path="/site-transaction/:id/verify"
                element={
                  <AuthRoute>
                    <SiteOrderVerification />
                  </AuthRoute>
                }
              />
              <Route
                path="/access-management/inventory"
                element={
                  <AuthRoute>
                    <AccessManagement />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />
              <Route
                path="/site-profile"
                element={
                  <AuthRoute>
                    <SiteProfile />
                  </AuthRoute>
                }
              />{" "}
              <Route
                path="/hr-dept"
                element={
                  <AuthRoute>
                    <HR />
                  </AuthRoute>
                }
              />
              <Route
                path="/inventory/transactions"
                element={
                  <AuthRoute>
                    <InventoryTransactionState />
                  </AuthRoute>
                }
              />
              <Route
                path="/inventory/transactions/:id"
                element={
                  <AuthRoute>
                    <InventoryTransactionState />
                  </AuthRoute>
                }
              />
            </Routes>
          </NotificationProvider>
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App;
