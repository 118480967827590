import {
  Box,
  HStack,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useLateGet } from "../../../Services/API/APIHandlers";
import { siteObject } from "../../../Services/Atoms/MaterialAtoms";

export const SiteTransactionTable = (props: TableProps) => {
  const [transactions, setTransactions] = useState<any>([]);
  const [dataSite, setDataSite] = useState(true);
  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);
  const [challanURL, setChallanUrl] = useState<string>("");
  const [itemsList, setItemsList] = useState<string>("");
  const site_id = localStorage.getItem("site_id");

  console.log(site_id);

  const {
    mutate: getSiteTransactionData,
    isLoading,
    error: siteTransactionError,
    data: siteTransactionData,
    isSuccess,
  } = useLateGet();

  const navigate = useNavigate();

  useEffect(() => {
    if (siteTransactionError instanceof AxiosError) {
      // Call a toast
      toast.error(siteTransactionError?.response?.data.message);
    }
  }, [siteTransactionError]);

  useEffect(() => {
    if (isSuccess) {
      console.log(siteTransactionData.data.transactionData);
      setTransactions(siteTransactionData.data.transactions);
    }
  }, [isSuccess, siteTransactionData]);

  useEffect(() => {
    if (site_id) getSiteTransactionData("api/transaction/site?siteId=" + site_id);
    window.scrollTo(0, 0);
  }, []);

  // Define the fields for CSV export
  const csvFields = [
    "Item Name",
    "Brand Name",
    "Length/Volume",
    "L/V Unit",
    "Quantity",
    "Qty Unit",
    "Position",
  ];

  // Format the data for CSV export
  const csvData = transactions
    ? transactions?.map((item: any) => ({
        "Item Name": item.item_name?.value,
        "Brand Name": item.brand_name,
        "Length/Volume": item.lv,
        "L/V Unit": item.lv_unit?.label,
        Quantity: item.quantity,
        "Qty Unit": item.qt_unit?.label,
        Position:
          (item.block?.label ? item.block?.label : "") +
          item.column?.label +
          item.cell?.label,
      }))
    : [];

  return (
    <>
      <Box
        className="mx-5 border-1"
        overflowY="auto"
        overflowX={"auto"}
        maxHeight="70vh"
      >
        <Table>
          <Thead position="sticky" top="0" zIndex="1" bg="gray.800">
            <Tr>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Challan Number</Text>
                  </HStack>
                </HStack>
              </Th>
              {/* <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Site Transfer</Text>
                  </HStack>
                </HStack>
              </Th> */}
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Challan Link</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Status</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Created At</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"15px"}>
            {transactions &&
              transactions.map((item: any, index: number) => (
                <Tr key={index}>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.deliveryChallanData?.delivery_challan_no || "N/A"}
                    </Text>
                  </Td>
                  {/* <Td>
                    <Text className="mb-0" color="muted">
                      {item.deliveryChallanData?.from_project_name &&
                      item.deliveryChallanData?.to_project_name
                        ? `${item.deliveryChallanData?.from_project_name} to ${item.deliveryChallanData?.to_project_name}`
                        : "N/A"}
                    </Text>
                  </Td> */}
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.challanLink ? (
                        <a
                          href={item.challanLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Challan
                        </a>
                      ) : (
                        "No Link"
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.status || "N/A"}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </Text>
                  </Td>
                  <Td>
                    <IconButton
                      variant={"ghost"}
                      className="rounded-circle"
                      aria-label="View Details"
                      onClick={() =>
                        navigate(`/site-transaction/${item._id}/verify`)
                      }
                      icon={<BsArrowRight fontSize="1rem" />}
                    />
                    {/* Placeholder for action buttons like edit or delete */}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
