import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGet, usePost } from "../../../../Services/API/APIHandlers";
import {
  currentItemsAtom,
  materialData,
  siteObject,
} from "../../../../Services/Atoms/MaterialAtoms";
import { formValuesTable } from "../../../../Services/Types/MaterialType";
import SiteSelect from "../../../components/SitesSelect";
import AddItemToSite from "../Forms/UserModal/AddItemSoSite";
import ExportCSV from "./../../../components/CSVExport/ExportToCSV";
import AddItemForm from "./../Forms/UserModal/AddItemForm";
import NewRequirement from "./../Forms/UserModal/NewRequirement";
import PullOutItems from "./../Forms/UserModal/PullOutItems";

type propType = {
  csvData: Record<string, any>[] | undefined;
  csvFields: string[];
};

interface Site {
  _id: string;
  siteId: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

function Header(props: propType) {
  const [items, setItems] = useRecoilState(materialData);
  const [currentItems, setCurrentItems] = useRecoilState<
    formValuesTable[] | null
  >(currentItemsAtom);
  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);
  const site_Id = localStorage.getItem("site_id");
  const siteId = localStorage.getItem("siteId");

  const [text, setText] = useState<string>("");

  const {
    mutate: getMaterialData,
    isLoading: isMaterialLoading,
    error: materialDataAllError,
    data: allMaterialsData,
    isSuccess: isAllMaterialSuccess,
  } = useGet("api/materials/all");

  const {
    mutate: search,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = useGet("api/materials/search?q=" + text);

  const {
    mutate: searchSite,
    isLoading: searchSiteLoading,
    error: searchSiteDataError,
    data: searchSiteData,
    isSuccess: searchSiteIsSuccess,
  } = usePost("api/materials/site/search-data?q=" + text);

  useEffect(() => {
    if (searchSiteDataError instanceof AxiosError) {
      // Call a toast
      toast.error(searchSiteDataError?.response?.data.message);
    }
  }, [searchSiteDataError]);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(materialsData.data);
      setCurrentItems(materialsData.data);
    }
  }, [isSuccess, materialsData]);

  useEffect(() => {
    if (searchSiteIsSuccess) {
      setItems(searchSiteData.data);
      setCurrentItems(searchSiteData.data);
    }
  }, [searchSiteIsSuccess, searchSiteData]);

  const {
    mutate: getSites,
    isLoading: isLoadingSite,
    error: siteDataError,
    data: siteData,
    isSuccess: siteDataSuccess,
  } = usePost("api/materials/site/get-site-data");

  useEffect(() => {
    if (isAllMaterialSuccess) {
      setItems(allMaterialsData.data);
      setCurrentItems(allMaterialsData.data);
    }
  }, [isAllMaterialSuccess, allMaterialsData]);

  useEffect(() => {
    if (text.length === 0) {
      getMaterialData();
    }
  }, [text]);

  const handleSiteChange = (site: Site | null) => {
    setSelectedSite(site);
    if (site && site?.siteId !== "site001") {
      getSites({
        siteId: site?.siteId,
      });
    } else {
      getMaterialData();
    }
  };

  useEffect(() => {
    if (siteId !== "site001")
      getSites({
        siteId: siteId,
      });
    else getMaterialData();
  }, []);

  useEffect(() => {
    if (siteDataSuccess) {
      setItems(siteData.data);
    }
  }, [siteDataSuccess, siteData]);

  return (
    <div
      style={{ zIndex: 10000 }}
      className="navbar navbar-sticky border-bottom mb-4"
    >
      <Box px={{ base: "4", md: "6" }} pt="5" pb="4">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="">
              <Link
                className="fs-7 py-1 d-flex flex-row align-items-center me-1"
                to="#"
                role="button"
                id="dropdownMenuLink"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <h4 className="my-0">Material Management</h4>
              </Link>
              <b className="b-4">
                Location:{" "}
                <i className="underline cursor-pointer text-secondary">
                  {selectedSite?.project_name}
                  {/* SITE: BPCS001: NIRSA DHANBAD */}
                </i>
              </b>
              <div className="mt-4">
                <SiteSelect siteId={siteId} onSiteChange={handleSiteChange} />
              </div>
            </div>
          </HStack>
        </Stack>
      </Box>
      <hr />

      <Box display={"flex"} px={{ base: "4", md: "6" }} pt="3">
        <Stack
          direction={{ base: "column", md: "column" }}
          justify={"end"}
          alignItems={"end"}
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {selectedSite?.siteId !== "site001" ? <NewRequirement /> : <></>}
            {selectedSite?.siteId !== "site001" ? (
              <AddItemToSite />
            ) : (
              <AddItemForm />
            )}

            <PullOutItems />
            <ExportCSV
              fields={props.csvFields}
              data={props.csvData}
              filename="materials.csv"
            />
          </HStack>
          <div className="d-flex align-items-center mt-4">
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                if (selectedSite?.siteId === "SITE001") search();
                else
                  searchSite({
                    siteId: selectedSite?.siteId,
                  });
              }}
            >
              <InputGroup maxW="xs">
                <InputRightElement pointerEvents="none"></InputRightElement>
                <Input
                  value={text}
                  w={"100%"}
                  onChange={(e: any) => setText(e.target.value)}
                  className="rounded-0 border"
                  placeholder="Search"
                />
                <button className="btn btn-primary text-light rounded-0 py-0 px-3 me-2">
                  <Icon
                    as={FaSearch}
                    // color="teal"
                    textColor={"#fff"}
                    boxSize="4"
                  />
                </button>
              </InputGroup>
            </form>

            {/* <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span> */}
          </div>
        </Stack>
      </Box>
    </div>
  );
}

export default Header;
