import React from "react";
import { Button } from "react-bootstrap";
import { CSVLink } from "react-csv";

interface ExportCSVProps {
  fields: string[];
  data: Record<string, any>[] | undefined;
  filename?: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({
  fields,
  data,
  filename = "export.csv",
}) => {
  const headers = fields.map((field: string) => ({ label: field, key: field }));

  if (data)
    return (
      <CSVLink data={data} headers={headers} filename={filename}>
        <Button className="btn btn-secondary rounded-0 fs-7 px-4">Export as CSV</Button>
      </CSVLink>
    );
  else return <></>;
};

export default ExportCSV;
