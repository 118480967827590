import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useGet, usePost } from "../../../Services/API/APIHandlers";

interface SiteOrderSlipValues {
  delivery_challan_no: string;
  date: string;
  name: string;
  address?: string;
}

const initialValues: SiteOrderSlipValues = {
  delivery_challan_no: "00001",
  date: new Date().toISOString().split("T")[0], // current date in YYYY-MM-DD
  name: "",
  address: "",
};

const texts = [
  {
    text: "NUMBER",
    size: 40,
    xCord: 180,
    yCord: 400,
    style: "600",
    font: "Arial",
  },
  {
    text: "DATE",
    size: 25,
    xCord: 1260,
    yCord: 390,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "NAME",
    size: 25,
    xCord: 170,
    yCord: 455,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "ADDRESS",
    size: 25,
    xCord: 220,
    yCord: 520,
    style: "900",
    font: "sans-serif",
  },
];

const signatureImages: never[] = [];

type pointerSet = {
  text: string;
  size: number;
  xCord: number;
  yCord: number;
  style: string;
  font: string;
};

type PropsType = {
  challanFormData?: any;
  formData?: Array<Array<pointerSet>> | null;
  setChallanFormData?: React.Dispatch<React.SetStateAction<any>>;
  challanURL: string;
  setChallanUrl: React.Dispatch<React.SetStateAction<string>>;
};

const validationSchema = Yup.object({
  delivery_challan_no: Yup.string().required("Delivery Challan No is required"),
  date: Yup.date().required("Date is required"),
  name: Yup.string().required("Name is required"),
});

const SiteOrderSlip = (props: PropsType) => {
  const site_id = localStorage.getItem("site_id");
  const [currentSiteDataAddress, setCurrentSiteDataAddress] =
    useState<string>();

  const signatureUrl = localStorage.getItem("signatureUrl");

  console.log(props.formData);

  const {
    mutate: getSiteData,
    data: siteData,
    error: siteError,
    isLoading: siteIsLoading,
    isSuccess: siteIsSuccess,
  } = useGet(`api/fetch-site/${site_id}`);

  // Handle form submission using usePost
  const {
    mutate: saveDeliveryChallan,
    data: saveChallanData,
    error: saveChallanError,
    isLoading: saveChallanIsLoading,
    isSuccess: saveChallanIsSuccess,
  } = usePost("api/generate-order-slip");

  // Success and error handling
  useEffect(() => {
    if (saveChallanIsSuccess) {
      toast.success("Order Slip created successfully!");
      alert(saveChallanData.data);
      props.setChallanUrl(saveChallanData.data.outputPath);
    }

    if (saveChallanError) {
      toast.error("Error saving the Delivery Challan.");
    }
  }, [saveChallanIsSuccess, saveChallanError, saveChallanData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const formValuesArray = [
        "00001",
        values.date,
        values.name,
        currentSiteDataAddress || "",
      ];

      const generatedData = texts.map((textObj, index) => ({
        ...textObj,
        text: formValuesArray[index] || textObj.text,
      }));

      saveDeliveryChallan({
        texts: generatedData,
        signatureImages: signatureImages,
        itemData: props.formData,
      });

      if (props.setChallanFormData) props.setChallanFormData({...values, address: currentSiteDataAddress});
    },
  });

  useEffect(() => {
    getSiteData();
  }, []);

  useEffect(() => {
    if (siteIsSuccess) {
      toast.success("Delivery Challan saved successfully!");
      setCurrentSiteDataAddress(siteData.data.address);
    }
  }, [siteIsSuccess, siteData]);

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <h3 className="pt-5 fw-bold">ORDER SLIP DETAILS</h3>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl
          isInvalid={
            !!formik.errors.delivery_challan_no &&
            formik.touched.delivery_challan_no
          }
        >
          <FormLabel>Delivery Challan No</FormLabel>
          <Input
            className="rounded-0"
            name="delivery_challan_no"
            value={formik.values.delivery_challan_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorMessage>
            {formik.errors.delivery_challan_no}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.date && formik.touched.date}>
          <FormLabel>Date</FormLabel>
          <Input
            className="rounded-0"
            type="date"
            name="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
          <FormLabel>Name (e.g., Store In Charge)</FormLabel>
          <Input
            name="name"
            className="rounded-0"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <Button
        mt={4}
        colorScheme="teal"
        isLoading={formik.isSubmitting}
        type="submit"
      >
        Submit
      </Button>
    </Box>
  );
};

export default SiteOrderSlip;
