import {
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiTrash2 } from "react-icons/fi";
import { RxCrossCircled } from "react-icons/rx";
import { useRecoilState } from "recoil";
import { useDeleteQuery } from "../../../../../Services/API/APIHandlers";
import { users } from "../../../../../Services/Atoms/AuthAtoms";
import { UserType } from "../../../../../Services/Types/UserTypes";
import { UserSchema } from "../../../../../Services/Validations/UserValidation";

type propType = {
  data: UserType;
};

function DeleteUser(props: propType) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [members, setMembers] = useRecoilState(users);

  // Handle form submission
  const handleSubmit = (values: UserType) => {
    // Handle form submission logic here
    console.log(values);
  };

  const initialValues: UserType = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    site: "",
    password: "",
    department: "",
    access_type: "",
    access_to_department: [] as string[],
  };

  const departments = [
    "INVENTORY",
    "ACCOUNTS",
    "HR",
    "FINANCE",
    "MARKETING",
    "PURCHASE",
    "TENDER",
  ];

  const {
    mutate: deleteUser,
    isLoading,
    error: userDataError,
    data: usersData,
    isSuccess,
  } = useDeleteQuery("api/users/" + props.data._id);

  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      // Call a toast
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMembers(
        members.filter((item: UserType) => item._id !== props.data._id)
      );
    }
  }, [isSuccess, usersData]);

  // useFormik hook
  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <IconButton
        icon={<FiTrash2 fontSize="1rem" />}
        className="rounded-circle"
        variant="ghost"
        aria-label="Delete member"
        onClick={onOpen}
      />
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"md"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ModalHeader>
              <h4 className="text-center">Are You Sure?</h4>
            </ModalHeader>

            <ModalBody
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              pb={7}
            >
              <Icon
                as={RxCrossCircled}
                color="muted"
                textColor={"red"}
                boxSize="100px"
              />
              <span>You want to delete this user?</span>
            </ModalBody>

            <ModalFooter>
              <Button
                onClick={(e: any) => {
                  e.preventDefault();
                  deleteUser();
                  onClose();
                }}
                marginRight={4}
                colorScheme={"red"}
              >
                {/* {isLoading ? <Spinner /> : "Delete"} */}
                Delete
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteUser;
