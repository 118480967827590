import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import Select from "react-select";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import { usePut } from "../../../../../Services/API/APIHandlers";
import {
  materialData,
  siteObject,
} from "../../../../../Services/Atoms/MaterialAtoms";

interface SelectOption {
  value: string | number;
  label: string | number;
}

const blockOptions: SelectOption[] = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "AT", label: "AT" },
  { value: "ABT", label: "ABT" },
  { value: "BCT", label: "BCT" },
  { value: "CT1", label: "CT1" },
  { value: "CT2", label: "CT2" },
  { value: "DT1", label: "DT1" },
  { value: "DT2", label: "DT2" },
];

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0, // No border radius
    borderColor: state.isFocused ? "red" : "#ddd", // Red when focused, gray otherwise
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? "red" : "#ddd", // Keep border color on hover
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: "black", // Black text color
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "black", // Ensure selected value's text is black
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 1000, // Ensure dropdown menu is on top
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "black", // Placeholder text color
  }),
};

const columnOptions: SelectOption[] = Array.from({ length: 16 }, (v, k) => ({
  value: k + 1,
  label: k + 1,
}));

const cellOptions: SelectOption[] = [
  { value: "001", label: "001" },
  { value: "002", label: "002" },
  { value: "003", label: "003" },
  { value: "004", label: "004" },
  { value: "005", label: "005" },
];

const validationSchema = yup.object().shape({
  item_name: yup.object().required("Item Name is required").nullable(),
  lv: yup.number().required("LV is required").nullable(),
  lv_unit: yup.object().required("LV Unit is required").nullable(),
  quantity: yup.number().required("Quantity is required").nullable(),
  qt_unit: yup.object().required("Quantity Unit is required").nullable(),
  block: yup.object().required("Block is required").nullable(),
  column: yup.object().required("Column is required").nullable(),
  cell: yup.object().required("Cell is required").nullable(),
});

type formValues = {
  item_name: { _id: string; label: string; value: string } | null;
  brand_name: string;
  lv: number | null;
  lv_unit: string | null | SelectOption;
  quantity: number | null;
  qt_unit: string | null | SelectOption;
  block: string | null | SelectOption;
  column: string | null | SelectOption;
  cell: string | null | SelectOption;
};

type formValuesTable = {
  _id: string;
  item_name: null | { _id: string; label: string; value: string };
  brand_name: string;
  lv: number | null;
  lv_unit: null | SelectOption;
  quantity: number | null;
  qt_unit: null | SelectOption;
  block: null | SelectOption;
  column: null | SelectOption;
  cell: null | SelectOption;
};

const lvUnitOptions: SelectOption[] = [
  { value: "inch", label: "inch" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
  { value: "ft", label: "ft" },
];

const qtUnitOptions: SelectOption[] = [
  { value: "kg", label: "kg" },
  { value: "litre", label: "litre" },
  { value: "gm", label: "gm" },
];

type editItemType = {
  data: formValuesTable;
};

const EditItem = (props: editItemType) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [materialItemData, setMaterialItemData] = useRecoilState(materialData);
  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);

  const formik = useFormik({
    initialValues: {
      item_name: props.data.item_name || null,
      brand_name: props.data.brand_name || "",
      lv: props.data.lv || null,
      lv_unit: props.data.lv_unit || null,
      quantity: props.data.quantity || null,
      qt_unit: props.data.qt_unit || null,
      block: props.data.block || null,
      column: props.data.column || null,
      cell: props.data.cell || null,
    },
    validationSchema,
    onSubmit: (values) => {
      updateMaterialItems({
        ...values,
        item_name: {
          label: values.item_name?.label,
          value: values.item_name?.value,
        },
      });
      onClose();
    },
  });

  useEffect(() => {
    formik.setValues({
      item_name: props.data.item_name || null,
      brand_name: props.data.brand_name || "",
      lv: props.data.lv || null,
      lv_unit: props.data.lv_unit || null,
      quantity: props.data.quantity || null,
      qt_unit: props.data.qt_unit || null,
      block: props.data.block || null,
      column: props.data.column || null,
      cell: props.data.cell || null,
    });
  }, [props.data]);

  const {
    mutate: updateMaterialItems,
    isLoading,
    error: materialDataError,
    data: materialsData,
    isSuccess,
  } = usePut("api/materials/" + props.data._id);

  useEffect(() => {
    if (materialDataError instanceof AxiosError) {
      // Call a toast
      toast.error(materialDataError?.response?.data.message);
    }
  }, [materialDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMaterialItemData(
        materialItemData &&
          materialItemData.map((item: any, index: number) => {
            if (item._id === materialsData.data._id) return materialsData.data;
            else return item;
          })
      );
    }
  }, [isSuccess, materialsData]);

  return (
    <>
      <IconButton
        className="rounded-circle"
        icon={<FiEdit2 fontSize="1rem" />}
        variant="ghost"
        colorScheme="green"
        aria-label="Update User Info"
        onClick={() => {
          console.log(formik.values);
          console.log(props.data);
          onOpen();
        }}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody style={{ padding: "20px" }}>
            <ModalCloseButton />
            <h4 className="mb-3">Update an Item</h4>

            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-0 mt-0 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Item Name"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          formik.setFieldValue("item_name", {
                            label: newValue,
                            value: newValue,
                          }); // Set both label and value
                        }}
                        value={
                          formik.values.item_name
                            ? formik.values.item_name.label
                            : ""
                        }
                        isInvalid={
                          formik.touched.item_name && !!formik.errors.item_name
                        }
                      />
                      {/* <Form.Group controlId="item_name">
                        <CustomSelectName
                          onChange={formik.handleChange}
                          value={formik.values.item_name}
                          label="Item Name"
                          style={customStyles} // Apply custom styles
                          options={
                            props.data.item_name ? [props.data.item_name] : []
                          }
                          isClearable
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.item_name}
                        </Form.Control.Feedback>
                      </Form.Group> */}
                    </Col>

                    <Col md={12}>
                      <Form.Group className="pt-3" controlId="lv">
                        <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                          Brand Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Brand"
                          className="rounded-0"
                          {...formik.getFieldProps("brand_name")}
                          isInvalid={
                            formik.touched.brand_name &&
                            !!formik.errors.brand_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.brand_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="lv">
                        <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                          LV
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="LV"
                          className="rounded-0"
                          {...formik.getFieldProps("lv")}
                          isInvalid={formik.touched.lv && !!formik.errors.lv}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors?.lv}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="lv_unit">
                        <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                          LV Unit
                        </Form.Label>
                        <Select
                          options={lvUnitOptions}
                          value={formik.values.lv_unit}
                          styles={customStyles} // Apply custom styles
                          onChange={(option) =>
                            formik.setFieldValue("lv_unit", option)
                          }
                          onBlur={() => formik.setFieldTouched("lv_unit", true)}
                        />
                        {formik.touched.lv_unit && formik.errors.lv_unit && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.lv_unit}
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="quantity">
                        <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                          Quantity
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          className="rounded-0"
                          {...formik.getFieldProps("quantity")}
                          isInvalid={
                            formik.touched.quantity && !!formik.errors.quantity
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.quantity}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="qt_unit">
                        <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                          Quantity Unit
                        </Form.Label>
                        <Select
                          options={qtUnitOptions}
                          value={formik.values.qt_unit}
                          styles={customStyles} // Apply custom styles
                          onChange={(option) =>
                            formik.setFieldValue("qt_unit", option)
                          }
                          onBlur={() => formik.setFieldTouched("qt_unit", true)}
                        />
                        {formik.touched.qt_unit && formik.errors.qt_unit && (
                          <div className="invalid-feedback d-block">
                            {formik.errors.qt_unit}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    {selectedSite?.siteId === "SITE001" ? (
                      <>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="block">
                            <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                              Block
                            </Form.Label>
                            <Select
                              options={blockOptions}
                              value={formik.values.block}
                              styles={customStyles} // Apply custom styles
                              onChange={(option) =>
                                formik.setFieldValue("block", option)
                              }
                              onBlur={() =>
                                formik.setFieldTouched("block", true)
                              }
                            />
                            {formik.touched.block && formik.errors.block && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.block}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="column">
                            <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                              Column
                            </Form.Label>
                            <Select
                              options={columnOptions}
                              value={formik.values.column}
                              onChange={(option) =>
                                formik.setFieldValue("column", option)
                              }
                              className="rounded-0"
                              styles={customStyles} // Apply custom styles
                              onBlur={() =>
                                formik.setFieldTouched("column", true)
                              }
                            />
                            {formik.touched.column && formik.errors.column && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.column}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="pt-3" controlId="cell">
                            <Form.Label className="badge text-dark pb-0 mb-0  mb-0 ">
                              Cell
                            </Form.Label>
                            <Select
                              options={cellOptions}
                              value={formik.values.cell}
                              styles={customStyles} // Apply custom styles
                              onChange={(option) =>
                                formik.setFieldValue("cell", option)
                              }
                              className="rounded-0"
                              onBlur={() =>
                                formik.setFieldTouched("cell", true)
                              }
                            />
                            {formik.touched.cell && formik.errors.cell && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.cell}
                              </div>
                            )}
                          </Form.Group>
                        </Col>{" "}
                      </>
                    ) : (
                      <></>
                    )}

                    <Col md={12}>
                      <Button
                        className="btn btn-primary rounded-0 mt-4 d-block text-uppercase w-100"
                        type="submit"
                      >
                        U p d a t e
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditItem;
