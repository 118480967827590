import {
  Box,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
} from "@chakra-ui/react";
import Header from "./Header";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import InventoryAccess from "./InventoryAccess";
import SiteAccess from "./SiteAccess";

const tabRoutes = {
  inventory: "/access-management/inventory",
  sites: "/access-management/sites",
};

export const AccessTabs = () => {
  const isMobile = useBreakpointValue({ base: false, md: false });
  const navigate = useNavigate(); // To navigate between routes
  const location = useLocation(); // To track the current location

  // Determine which tab to activate based on the current URL
  const getCurrentTab = () => {
    if (location.pathname === tabRoutes.inventory) return "inventory";
    if (location.pathname === tabRoutes.sites) return "sites";
    return "inventory"; // Default to 'inventory' if path doesn't match
  };

  // Set the current tab on load
  const [key, setKey] = React.useState<string>(getCurrentTab());

  useEffect(() => {
    setKey(getCurrentTab()); // Sync the tab state with the URL
  }, [location.pathname]);

  // Handle tab change and update the URL accordingly
  const handleSelect = (k: string | null) => {
    if (k) {
      setKey(k);
      navigate(tabRoutes[k as keyof typeof tabRoutes]); // Change route when tab is clicked
    }
  };

  return (
    <Box
      bg="bg-surface"
      boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
      borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
    >
      <Header />
      <Stack spacing="5">
        <Box overflowX="auto">
          <Tabs
            activeKey={key}
            onSelect={handleSelect} // Handle tab selection
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="inventory" title="Inventory">
              <Card>
                <CardHeader>
                  <Heading size="md">Inventory Access</Heading>
                </CardHeader>
                <CardBody>
                  <InventoryAccess />
                </CardBody>
              </Card>
            </Tab>
            <Tab eventKey="sites" title="Sites">
              <Card>
                <CardHeader>
                  <Heading size="md">Site Access</Heading>
                </CardHeader>
                <CardBody>
                  <SiteAccess />
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </Box>
      </Stack>
    </Box>
  );
};
