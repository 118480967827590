import {
  Avatar,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { RiInbox2Line } from "react-icons/ri";
// import CreateUser from "../../Pages/Users/Forms/UserModal/CreateUser";
// import DeleteUser from "../../Pages/Users/Forms/UserModal/DeleteUser";
import axios from "axios";
import { useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { profilePhotoUrl } from "../../../Services/Atoms/AuthAtoms";
import { BASE_URL } from "../../../Services/Config";
import NotificationSystem from "./../../../components/NotificationManager";

function Navbar() {
  const navigate = useNavigate();
  const [profilePicUrlActive, setProfilePicUrlActive] =
    useRecoilState(profilePhotoUrl);

  useEffect(() => {
    setProfilePicUrlActive(localStorage.getItem("profilePicUrl") || "");
  }, []);

  return (
    <nav
      style={{ zIndex: 20000 }}
      className="navbar sticky-top navbar-expand-lg border-bottom text-light  navbar-light bg-dark"
    >
      <div className="container-fluid  text-light">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse  text-light"
          id="navbarSupportedContent"
        >
          {/* <InputGroup maxW="xs">
            <InputRightElement pointerEvents="none">
              <button className="btn btn-light border rounded py-0 px-2 me-2">
                <Icon
                  as={RxSlash}
                  color="muted"
                  textColor={"#aaa"}
                  boxSize="3"
                />
              </button>
            </InputRightElement>
            <Input placeholder="Search" />
          </InputGroup> */}
          <h3 style={{ color: "#fff !important" }}>
            BPC INFRAPROJECTS PVT. LTD
          </h3>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
            <li className="nav-item">
              <IconButton
                aria-label=""
                variant={"ghost"}
                className="rounded-circle"
                icon={<RiInbox2Line fontSize="1.25rem" />}
              />
            </li>
            <li className="nav-item me-2">
              <NotificationSystem />
            </li>
            <li></li>
            <li className="nav-item">
              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <Avatar size={"sm"} src={profilePicUrlActive} />
                  </MenuButton>
                  <MenuList width={"fit-content"}>
                    <MenuItem color={"#000"}>
                      <Icon color={"#000"} as={CgProfile}></Icon> &nbsp; Profile
                    </MenuItem>
                    <MenuItem
                      color={"#000"}
                      onClick={(e) => {
                        sessionStorage.clear();
                        localStorage.clear();
                        axios
                          .get(BASE_URL + "logout")
                          .then((response) => {
                            console.log(response);
                            navigate("/");
                          })
                          .catch((error) => {
                            console.log(error.message);
                            navigate("/");
                          });
                      }}
                    >
                      <Icon as={RiLogoutBoxLine}></Icon> &nbsp; Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
