import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { usePut } from "../../../../../Services/API/APIHandlers";
import { users } from "../../../../../Services/Atoms/AuthAtoms";
import { UserType } from "../../../../../Services/Types/UserTypes";
import { UserSchema } from "../../../../../Services/Validations/UserValidation";
import SiteSelect from "../../../../components/SitesSelect";

type propType = {
  data: UserType;
};

function UpdateUser(props: propType) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [members, setMembers] = useRecoilState(users);

  // Handle form submission
  const handleSubmit = (values: UserType) => {
    console.log(values);
  };

  const departments = [
    "MATERIAL_MANAGEMENT",
    "ACCOUNTS",
    "HR",
    "FINANCE",
    "MARKETING",
    "PURCHASE",
    "TENDER",
  ];

  const {
    mutate: updateUser,
    isLoading,
    error: userDataError,
    data: userData,
    isSuccess,
  } = usePut("api/users/" + props.data._id);

  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      // Call a toast
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  useEffect(() => {
    if (isSuccess) {
      console.log(userData);
      setMembers(
        members.map((item: UserType) => {
          if (item._id !== props.data._id) {
            return item;
          } else {
            return userData.data.user;
          }
        })
      );
    }
  }, [isSuccess, userData]);

  const handleCheckboxChange = (event: any) => {
    const { value, checked } = event.target;
    if (checked) {
      formik.setFieldValue("access_to_department", [
        ...formik.values.access_to_department,
        value,
      ]);
    } else {
      formik.setFieldValue(
        "access_to_department",
        formik.values.access_to_department.filter((item) => item !== value)
      );
    }
  };

  // useFormik hook
  const formik = useFormik({
    initialValues: props.data,
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <IconButton
        className="rounded-circle"
        icon={<FiEdit2 fontSize="1rem" />}
        variant="ghost"
        aria-label="Update User Info"
        onClick={onOpen}
      />

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={"20px"}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Update User Info</h4>
            <Form>
              <Card className="rounded-5 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="first_name"
                          placeholder="First Name"
                          {...formik.getFieldProps("first_name")}
                          isInvalid={
                            formik.touched.first_name &&
                            !!formik.errors.first_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="last_name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="last_name"
                          placeholder="Last Name"
                          {...formik.getFieldProps("last_name")}
                          isInvalid={
                            formik.touched.last_name &&
                            !!formik.errors.last_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.last_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          {...formik.getFieldProps("email")}
                          isInvalid={
                            formik.touched.email && !!formik.errors.email
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="site">
                        <Form.Label>Site</Form.Label>
                        <SiteSelect
                          siteId={formik.values.site}
                          onSiteChange={(site) => {
                            formik.setFieldValue("site_id", site?._id);
                            formik.setFieldValue("siteId", site?.siteId);
                            formik.setFieldValue(
                              "from_project_name",
                              site?.address
                            );
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.site}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          {...formik.getFieldProps("phone")}
                          isInvalid={
                            formik.touched.phone && !!formik.errors.phone
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          {...formik.getFieldProps("password")}
                          isInvalid={
                            formik.touched.password && !!formik.errors.password
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="department">
                        <Form.Label>Department</Form.Label>
                        <Form.Control
                          as="select"
                          {...formik.getFieldProps("department")}
                          isInvalid={
                            formik.touched.department &&
                            !!formik.errors.department
                          }
                        >
                          <option value="">Select Department</option>
                          <option value="MATERIAL_MANAGEMENT">
                            MATERIAL MANAGEMENT
                          </option>
                          <option value="ACCOUNTS">ACCOUNTS</option>
                          <option value="HR">HR</option>
                          <option value="FINANCE">FINANCE</option>
                          <option value="MARKETING">MARKETING</option>
                          <option value="PURCHASE">PURCHASE</option>
                          <option value="TENDER">TENDER</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.department}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="access_type">
                        <Form.Label>Access Type</Form.Label>
                        <Form.Control
                          as="select"
                          {...formik.getFieldProps("access_type")}
                          isInvalid={
                            formik.touched.access_type &&
                            !!formik.errors.access_type
                          }
                        >
                          <option value="">Select Access Type</option>
                          <option value="SUPER ADMIN">SUPER ADMIN</option>
                          <option value="ADMIN">ADMIN</option>
                          <option value="EMPLOYEE">EMPLOYEE</option>
                          <option value="BASIC">BASIC</option>
                          <option value="HEAD_ADMIN">HEAD ADMIN</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.access_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        controlId="access_to_department"
                        className="pt-3"
                      >
                        <Form.Label>Access to Department</Form.Label>
                        {departments.map((department) => (
                          <Form.Check
                            key={department}
                            type="checkbox"
                            label={department}
                            value={department}
                            checked={formik.values.access_to_department.includes(
                              department
                            )}
                            onChange={handleCheckboxChange}
                          />
                        ))}
                        {formik.errors.access_to_department && (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.access_to_department}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  console.log(formik.values);
                  updateUser(formik.values);
                  onClose();
                }}
                className="btn btn-primary mt-4 d-block text-uppercase w-100 fw-bold"
                type="submit"
              >
                Update
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateUser;
