import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useGet } from "../../../Services/API/APIHandlers";
import { BASE_URL } from "../../../Services/Config";
import { UserType } from "../../../Services/Types/UserTypes";

const InventoryAccess = () => {
  const [members, setMembers] = useState<UserType[]>([]);
  const [accessRoles, setAccessRoles] = useState<any>(null); // For storing fetched access roles

  // Fetch users data
  const {
    mutate: getUsers,
    isLoading,
    error: userDataError,
    data: usersData,
    isSuccess,
  } = useGet("api/users/");

  // Handle API error
  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  // Set user data to state if successful
  useEffect(() => {
    if (isSuccess) {
      setMembers(usersData.data);
    }
  }, [isSuccess, usersData]);

  // Fetch existing Inventory segment access roles
  useEffect(() => {
    const fetchAccessRoles = async () => {
      try {
        const { data } = await axios.get(
          `${BASE_URL}api/access-management/segment/Inventory`
        );
        formik.setValues({
          hoApprovalAuthority1: data.users[0],
          hoApprovalAuthority2: data.users[1],
        });
        setAccessRoles(data);
      } catch (error) {
        toast.error("Error fetching inventory segment access roles.");
      }
    };

    fetchAccessRoles();
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      hoApprovalAuthority1: "", // Populate from access roles
      hoApprovalAuthority2: "", // Populate from access roles
    },
    enableReinitialize: true, // Allow the form to update with fetched accessRoles data
    validationSchema: Yup.object({
      hoApprovalAuthority1: Yup.string().required(
        "HO Approval Authority 1 is required"
      ),
      hoApprovalAuthority2: Yup.string().required(
        "HO Approval Authority 2 is required"
      ),
    }),
    onSubmit: async (values) => {
      const dataToSubmit = {
        segment: "Inventory", // Set default segment to "inventory"
        levels: 2, // Always 2 levels for the users and signatures
        users: [values.hoApprovalAuthority1, values.hoApprovalAuthority2], // Selected users
        signatureUrls: ["", ""], // Set signatureUrls to empty strings by default
      };

      try {
        if (accessRoles) {
          // Update request
          await axios.put(
            `${BASE_URL}api/access-management/${accessRoles._id}`,
            dataToSubmit
          );
          toast.success("Access management entry updated successfully!");
        } else {
          // Create request
          await axios.post(`${BASE_URL}api/access-management`, dataToSubmit);
          toast.success("Access management entry created successfully!");
        }
      } catch (error) {
        toast.error("Error submitting the form");
      }
    },
  });

  // Fetch users when component mounts
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Container>
        <Row>
          <Col md={6}>
            <Form.Group controlId="hoApprovalAuthority1">
              <Form.Label>HO Approval Authority 1</Form.Label>
              <Form.Control
                as="select"
                name="hoApprovalAuthority1"
                value={formik.values.hoApprovalAuthority1}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.hoApprovalAuthority1 &&
                  formik.touched.hoApprovalAuthority1
                }
              >
                <option value="">Select Approval Authority 1</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.hoApprovalAuthority1}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="hoApprovalAuthority2">
              <Form.Label>HO Approval Authority 2</Form.Label>
              <Form.Control
                as="select"
                name="hoApprovalAuthority2"
                value={formik.values.hoApprovalAuthority2}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.hoApprovalAuthority2 &&
                  formik.touched.hoApprovalAuthority2
                }
              >
                <option value="">Select Approval Authority 2</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.hoApprovalAuthority2}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Container>

      <Button type="submit" className="mt-3" disabled={isLoading}>
        {isLoading ? "Updating..." : "Update"}
      </Button>
    </Form>
  );
};

export default InventoryAccess;
