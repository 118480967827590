import { Box } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { BASE_URL } from "../../../Services/Config";

interface OrderSlipSelectProps {
  onSelectChange: (value: string | null) => void;
  id?: string;
  disabled?: boolean;
}

const OrderSlipSelect: React.FC<OrderSlipSelectProps> = ({
  onSelectChange,
  id,
  disabled,
}) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  // Fetch all transactions if no ID is passed
  useEffect(() => {
    if (!id) {
      axios
        .get(`${BASE_URL}api/transaction/site`)
        .then((response) => {

          console.log(response.data.transactions);

          const options = response.data.transactions.map((transaction: any) => ({
            value: transaction.deliveryChallanData.delivery_challan_no,
            label: transaction.deliveryChallanData.delivery_challan_no,
          }));
          setOptions(options);
          console.log(options)
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [id]);

  // Fetch transaction details if an ID is passed
  useEffect(() => {
    if (id) {
      axios
        .get(`${BASE_URL}api/transaction/site/${id}`)
        .then((response) => {
          const transaction = response.data;
          const option = {
            value: transaction._id,
            label: transaction.deliveryChallanData.delivery_challan_no,
          };
          setSelectedOption(option);
        })
        .catch((error) => {
          console.error("Error fetching transaction:", error);
        });
    }
  }, [id]);

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected);
    onSelectChange(selected ? selected.value : null);
  };

  return (
    <Box minWidth={100} zIndex={1000}>
      <CreatableSelect
        options={options}
        value={selectedOption}
        isDisabled={disabled}
        onChange={handleSelectChange}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: 0,
            borderColor: "#ccc",
            color: "black",
          }),
          menu: (base) => ({
            ...base,
            borderRadius: 0,
          }),
          option: (base, state) => ({
            ...base,
            color: "black",
            backgroundColor: state.isSelected ? "#f5f5f5" : "white",
            ":hover": {
              backgroundColor: "#f0f0f0",
            },
          }),
          singleValue: (base) => ({
            ...base,
            color: "black",
          }),
        }}
      />
    </Box>
  );
};

export default OrderSlipSelect;
