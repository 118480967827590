import { AccessTabs } from "./AccessTabs";


function AccessManagement() {
  return (
    <div>
      <AccessTabs />
    </div>
  );
}

export default AccessManagement;
