import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { usePost } from "../../../../../Services/API/APIHandlers";
import { UserType } from "../../../../../Services/Types/UserTypes";

// import { useFormik } from 'formik';
import * as Yup from "yup";
import { siteData } from "../../../../../Services/Atoms/MaterialAtoms";

function CreateSite() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [members, setMembers] = useRecoilState(siteData);

  // const handleSubmit = (values: UserType) => {
  //   createNewSite(values);
  // };

  const initialValues: UserType = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    site: "",
    password: "",
    department: "",
    access_type: "",
    access_to_department: [] as string[],
  };

  const departments = [
    "MATERIAL_MANAGEMENT",
    "ACCOUNTS",
    "HR",
    "FINANCE",
    "MARKETING",
    "PURCHASE",
    "TENDER",
  ];

  const {
    mutate: createNewSite,
    isLoading,
    error: createNewSiteError,
    data: createNewSiteData,
    isSuccess: createNewSiteSuccess,
  } = usePost("api/create-site");

  useEffect(() => {
    if (createNewSiteError instanceof AxiosError) {
      // Call a toast
      toast.error(createNewSiteError?.response?.data.message);
    }
  }, [createNewSiteError]);

  useEffect(() => {
    if (createNewSiteSuccess) {
      setMembers(createNewSiteData.data.sites);
    }
  }, [createNewSiteSuccess, createNewSiteData]);

  // useFormik hook
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: UserSchema,
  //   onSubmit: handleSubmit,
  // });

  const formik = useFormik({
    initialValues: {
      project_name: "",
      project_id: "",
      address: "",
      department: "",
      start_date: "",
      end_date: "",
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Required"),
      project_id: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      department: Yup.string().required("Required"),
      start_date: Yup.date().required("Required"),
    }),
    onSubmit: (values) => {
      const { end_date, ...rest } = values;
      createNewSite(rest);
      onClose();
    },
  });

  // const handleCheckboxChange = (event: any) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     formik.setFieldValue("access_to_department", [
  //       ...formik.values.access_to_department,
  //       value,
  //     ]);
  //   } else {
  //     formik.setFieldValue(
  //       "access_to_department",
  //       formik.values.access_to_department.filter((item) => item !== value)
  //     );
  //   }
  // };

  return (
    <>
      <Button
        onClick={onOpen}
        className="dropdown-item"
        variant="solid"
        colorScheme="teal"
      >
        Create Project
      </Button>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size={"xl"}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody padding={"20px"}>
            <ModalCloseButton />
            <h4 className="text-center mb-3">Create a Project</h4>
            <Form onSubmit={formik.handleSubmit}>
              <Card className="rounded-5 border-muted">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="project_name">
                        <Form.Label>Name of Project</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name of Project"
                          {...formik.getFieldProps("project_name")}
                          isInvalid={
                            formik.touched.project_name &&
                            !!formik.errors.project_name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.project_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="project_id">
                        <Form.Label>Project ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Project ID"
                          {...formik.getFieldProps("project_id")}
                          isInvalid={
                            formik.touched.project_id &&
                            !!formik.errors.project_id
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.project_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          {...formik.getFieldProps("address")}
                          isInvalid={
                            formik.touched.address && !!formik.errors.address
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="department">
                        <Form.Label>Name Of Department</Form.Label>
                        <Form.Control
                          as="select"
                          {...formik.getFieldProps("department")}
                          isInvalid={
                            formik.touched.department &&
                            !!formik.errors.department
                          }
                        >
                          <option value="">Select Department</option>
                          <option value="MATERIAL_MANAGEMENT">
                            MATERIAL MANAGEMENT
                          </option>
                          <option value="ACCOUNTS">ACCOUNTS</option>
                          <option value="HR">HR</option>
                          <option value="FINANCE">FINANCE</option>
                          <option value="MARKETING">MARKETING</option>
                          <option value="PURCHASE">PURCHASE</option>
                          <option value="TENDER">TENDER</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.department}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="pt-3" controlId="start_date">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("start_date")}
                          isInvalid={
                            formik.touched.start_date &&
                            !!formik.errors.start_date
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.start_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    {/* <Col md={6}>
                      <Form.Group className="pt-3" controlId="end_date">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          {...formik.getFieldProps("end_date")}
                          isInvalid={
                            formik.touched.end_date && !!formik.errors.end_date
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.end_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
              <Button
                className="btn btn-primary mt-4 d-block text-uppercase w-100 fw-bold"
                type="submit"
              >
                Create Project
              </Button>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateSite;
