import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Input,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoArrowDown } from "react-icons/io5";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { users } from "../../../../Services/Atoms/AuthAtoms";
import DeleteUser from "../Forms/UserModal/DeleteUser";
import UpdateUser from "./../Forms/UserModal/UpdateUser";
import { fetchDate } from "../../../../Services/DefaultFunctions";
// import { members } from "./data";

export const MemberTable = (props: TableProps) => {
  const [members, setMembers] = useRecoilState(users);

  const {
    mutate: getUsers,
    isLoading,
    error: userDataError,
    data: usersData,
    isSuccess,
  } = useGet("api/users/");

  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      // Call a toast
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  useEffect(() => {
    if (isSuccess) {
      setMembers(usersData.data);
    }
  }, [isSuccess, usersData]);

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      className="mx-5 border-1"
      overflowY="auto"
      overflowX={"auto"}
      maxHeight="70vh"
    >
      <Table {...props}>
        <Thead position="sticky" top="0" zIndex="1" bg="gray.800">
          <Tr>
            <Th>
              <HStack spacing="3">
                <Checkbox />
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Name</Text>
                  <Icon as={IoArrowDown} color="muted" boxSize="4" />
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                {/* <Checkbox /> */}
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Employee ID</Text>
                  <Icon as={IoArrowDown} color="muted" boxSize="4" />
                </HStack>
              </HStack>
            </Th>
            {/* <Th>
          <HStack spacing="3">
            <Checkbox />
            <HStack spacing="1">
              <Text className="mb-0 text-light">Name</Text>
              <Icon as={IoArrowDown} color="muted" boxSize="4" />
            </HStack>
          </HStack>
        </Th> */}
            <Th>
              <HStack spacing="3">
                <Checkbox />
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Email</Text>
                  <Icon as={IoArrowDown} color="muted" boxSize="4" />
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Phone Number</Text>
                  <Icon as={IoArrowDown} color="muted" boxSize="4" />
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Password</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Access Type</Text>
                </HStack>
              </HStack>
            </Th>
            <Th>
              <HStack spacing="3">
                <HStack spacing="1">
                  <Text className="mb-0 text-light">Created At</Text>
                </HStack>
              </HStack>
            </Th>

            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody fontSize={"15px"}>
          {members.map((member, index) => (
            <Tr key={index}>
              <Td>
                <HStack spacing="3">
                  <Checkbox />
                  <Avatar
                    name={member.first_name + " " + member.last_name}
                    // src={member.avatarUrl}
                    boxSize="10"
                  />
                  <Box>
                    <Text className="mb-0" fontWeight="medium">
                      {member.first_name + " " + member.last_name}
                    </Text>
                  </Box>
                </HStack>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  {member.employeeID}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {member.email}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {member.phone}
                </Text>
              </Td>
              <Td>
                <Text className="mb-0" color="muted">
                  <Input
                    placeholder="Search"
                    type="password"
                    size="sm"
                    disabled
                    value={member.password}
                    width={"auto"}
                  />
                </Text>
              </Td>
              <Td>
                <Badge
                  size="sm"
                  colorScheme={member.access_type === "admin" ? "green" : "red"}
                >
                  {member.access_type}
                </Badge>
              </Td>
              <Td>
                <Text className="mb-0" color="#777">
                  {fetchDate(member.createdAt)}
                </Text>
              </Td>
              <Td>
                <HStack spacing="1">
                  <DeleteUser data={member} />
                  <UpdateUser data={member} />
                  <IconButton
                    variant={"ghost"}
                    className="rounded-circle"
                    aria-label="View Details"
                    icon={<HiOutlineDotsVertical fontSize="1rem" />}
                  />
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
